
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'disclaimer-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('disclaimer'), [
          translate('settings'),
        ]);
      });

      await store.dispatch(Actions.GET_DISCLAIMERS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('screen'),
          key: 'screen',
          sortable: true,
        },

        {
          name: translate('descriptionEn'),
          key: 'descEn',
          sortable: true,
        },
        {
          name: translate('descriptionAr'),
          key: 'descAr',
          sortable: true,
        },

        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const editDisclaimer = (id) => {
        router.push({ name: 'disclaimer-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_DISCLAIMER_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_DISCLAIMERS);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.disclaimerList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.disclaimerListCount),
        pageChanged,
        goTo,
        editDisclaimer,
        loading,
        can,
      };
    },
  });
